export default [
  {
    id: "apmMaersk",
    name: "MAERSK",
    URL: "https://www.maersk.com/schedules/pointToPoint",
  },
  {
    id: "msc",
    name: "MSC",
    URL: "https://www.msc.com/rate-enquiry",
  },
  {
    id: "one",
    name: "ONE",
    URL: "https://ecomm.one-line.com/ecom/CUP_HOM_3701.do",
  },
  {
    id: "evergreen",
    name: "EVERGREEN",
    URL: "https://www.evergreen-line.com",
  },
  {
    id: "hmm",
    name: "HYUNDAI MM",
    url: "https://www.hmm21.com/company.do"
  },
  {
    id: "cosco",
    name: "COSCO",
    URL: "https://elines.coscoshipping.com/ebusiness/rateQuotations",
  },
  {
    id: "pil",
    name: "PIL",
    url: "https://www.pilship.com/en-pil-pacific-international-lines/1.html"
  }
]

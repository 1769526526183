export default {
  ERROR_TYPE_CONTAINER_SEARCH: "It seems there is no availability of that type of container in the selected region.",
  ERROR_EMPTY_SEARCH:
    "Seems we could not find what you were looking for with you current search criteria.",
  FORM_SIMPLE: {
    PICKUP_LOCATION_REQUIRED: "The <b>Pickup Location</b> field is required",
    PICKUP_LOCATION_EXISTS: "The <b>Pickup Location</b> field does not exist",
    DROP_OFF_LOCATION_REQUIRED: "The <b>Drop-Off Location</b> field is required",
    DROP_OFF_LOCATION_EXISTS: "The <b>Drop-Off Location</b> field does not exist",
    DROP_OFF_LOCATION_SAME: "The <b>Drop-Off Location</b> field cannot be the same as  the <b>Pickup Location</b> field",
    SEARCH_START_DATE_REQUIRED: "The <b>Search Departure Date</b> field is required",
  },
  FORM_ADVANCE: {
    ORIGIN_LOCATION_REQUIRED: "The <b>Location</b> field is required",
    ORIGIN_LOCATION_EXISTS: "The <b>Location</b> field does not exist",
    ORIGIN_LOCATION_CHECKBOX: "Select option CY or SD",
    DESTINATION_LOCATION_REQUIRED: "The <b>Location</b> field is required",
    DESTINATION_LOCATION_EXISTS: "The <b>Location</b> field does not exist",
    DESTINATION_LOCATION_CHECKBOX: "Select option CY or SD",
    CONTAINER_DETAILS_TYPE_REQUIRED: "Select an option in the <b>Container Type</b> field",
    CONTAINER_DETAILS_SIZE_REQUIRED: "Select an option in the <b>Container Size</b> field",
    CONTAINER_DETAILS_QUANTITY_NUMBER: "The <b>Container Quantity</b> field must be number",
    CONTAINER_DETAILS_QUANTITY_MIN: "The <b>Container Quantity</b> filed cannot be 0",
    CONTAINER_DETAILS_WEIGHT_MIN: "The <b>Weight</b> filed cannot be 0",
    COMMODITY_EXISTS: "The <b>Commodity</b> field does not exist",
    COMMODITY_IMO_REQUIRED: "The <b>IMO Number</b> field is required",
    COMMODITY_IMO_NUMBER: "The <b>IMMO Number</b> field must be number",
    COMMODITY_IMO_MIN: "The <b>IMMO Number</b> filed cannot be 0",
    SEARCH_START_DATE_REQUIRED: "The <b>Search Departure Date</b> field is required",
    COMPANY_DETAILS_NAME_REQUIRED: "The <b>Company Name</b> field is required",
    COMPANY_DETAILS_EMAIL_REQUIRED: "The <b>Email Address</b> field is required",
    COMPANY_DETAILS_IS_EMAIL: "The <b>Email Address</b> field is invalid",
    COMPANY_DETAILS_LOCATION_REQUIRED: "The <b>Location</b> field is required",
    COMPANY_DETAILS_LOCATION_EXISTS: "The <b>Location</b> field does not exist",
  },
  FORM_CONTACT_US: {
    NAME_REQUIRED: "The field is required",
    EMAIL_REQUIRED: "The field is required",
    EMAIL_INVALID: "The field is invalid",
    SUBJECT_REQUIRED: "The field is required",
    MESSAGE_REQUIRED: "The field is required",
  },
}
